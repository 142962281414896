@import "./icons.scss";
@import "./tailwind.scss";
@import "../../../node_modules/prismjs/themes/prism-okaidia.css"; 
@import "../../../node_modules/lightbox.js-react/dist/index.css";

.flatpickr-days {
  padding-top: 5px !important;
}

.flatpickr-prev-month {
  margin-top: 6px !important;
  margin-left: 9px !important;
}

.flatpickr-next-month {
  margin-top: 6px !important;
  margin-right: 9px !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-always-visible {
  scrollbar-width: thin; /* For Firefox */
}

.scrollbar-always-visible::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar */
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.scrollbar-always-visible::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Color of the scrollbar track */
}